/* eslint-disable */

import React from 'react';
import './Projects.css'; 
import HireX from '../../assets/images/HireX.png';
import Strangely from '../../assets/images/Strangely.png';
import MySql from '../../assets/images/MySql.png';
import PersonalFinance from '../../assets/images/PersonalFinance.png';
import K8s from '../../assets/images/K8s.png';
import MainLayout from '../MainLayout/MainLayout';

const ProjectCard = ({ image, title, description, tools, codeLink }) => {
  return (
    
    <div className="project-card">
      <img src={image} alt={title} className="project-image" />
      <div className="project-details">
        <h3 className="project-title">{title}</h3>
        <p className="project-description">{description}</p>
        <p className="project-tools">{tools}</p>
        <a href={codeLink} target="_blank" rel="noopener noreferrer" className="project-code-link">Code (GitHub)</a>
      </div>
    </div>
    
  );
};

const Projects = () => {
  const projectsData = [
    {
      image: PersonalFinance, // replace with your image path
      title: 'Personal Finance App',
      description: 'An app that makes use of AWS tools and technologies to help calculate tax according to provinces and calculate metrics for your personal investment portfolio. Leveraged AWS Lambda, API Gateway, S3, DynamoDB, Textract, EventBridge. EC2 and the use of Cloudformation to provision resources.',
      tools: 'Node.js, ReactJS, AWS, IaC',
      codeLink: 'https://github.com/Nashyn/Personal-Finance-App'
    },
    {
      image: HireX, // replace with your image path
      title: 'HireX',
      description: 'A Hiring Platform that provides a seamless experience to candidates, recruiters and interviewers throughout the hiring process and tackles the task to centralize the process. Integrated the Code editor, Video Call, and Chat functionalities.',
      tools: 'ReactJS, Springboot, Docker, JWT, WebSocket',
      codeLink: 'https://github.com/Nashyn/HireX'
    },
    {
      image: Strangely, // replace with your image path
      title: 'Strangely',
      description: 'A Social Media WebApp that allows Strangers to connect with each other and build an interactive community through self hosted events. Used CI/CD pipeline to deploy the application, ran Junits and Integration tests to ensure the quality of the code, removed code smells and followed SOLID principles.',
      tools: 'ReactJS, Springboot, Docker, JWT, TDD, Code Quality, CI/CD, SOLID',
      codeLink: 'https://github.com/Nashyn/Strangely'
    },
    {
      image: MySql, // replace with your image path
      title: 'MySql Prototype',
      description: 'A lightweight MySql Prototype that makes use of java to build the same functionality. Implemented the concept of transactions and ACID properties to ensure the consistency of the database.',
      tools: 'Java',
      codeLink: 'https://github.com/Nashyn/MySql-Prototype'
    },
    {
      image: K8s, // replace with your image path
      title: 'Kubernetes Microservice Architecture',
      description: 'A Kubernetes Microservice Architecture that makes use of Docker and Kubernetes to deploy a scalable and reliable architecture. Built 2 microservices that stores a file in cluster and operation were performed on that file. Deployed with CodeBuild and Leveraged the use of ArtifacT Registry, GKE cluster and Terraform as IaC tool.',
      tools: 'SpringBoot, GCP, GKE, Docker, Terraform',
      codeLink: 'https://github.com/Nashyn/K8s'
    }
  ];

  return (
    <MainLayout>
    <div className="projects-container">
      {projectsData.map((project, index) => (
        <ProjectCard key={index} {...project} />
      ))}
    </div>
    </MainLayout>
  );
};

export default Projects;
