/* eslint-disable */
import React from 'react';
import './Education.css'; 
import MainLayout from '../MainLayout/MainLayout';

const Education = () => {
  return (
    <MainLayout>
    <div className="education-container">
     
      <div className="education-content">
        <section className="education-section">
          <h2>Master of Applied Computer Science</h2>
          <p>Dalhousie University, 2023 - 2025</p>
          <ul>
            <li>Advanced Software Development Concepts</li>
            <li>Data Management - Warehousing & Analytics</li>
            <li>Advanced Cloud Computing</li>
            <li>Advanced Cloud Architecting</li>
            <li>Advanced Network Security</li>
            <li>Serverless Data Processing</li>
            <li>Advanced Computer Networks</li>
          </ul>
        </section>
        
        <section className="education-section">
          <h2>Masters Of Business Administration in Information Technology</h2>
          <p>NMIMS University, 2017 - 2023</p>
          <ul>
            <li>Applied Mathematics</li>
            <li>Data Mining</li>
            <li>Software Engineering</li>
            <li>Design and Analysis of Algorithms</li>
            <li>Database Management Systems</li>
            <li>Computer Networks</li>
            <li>Machine Learning</li>
          </ul>
        </section>
      </div>
    </div>
    </MainLayout>
  );
};

export default Education;
