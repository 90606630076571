/* eslint-disable */
import React from "react";
import "./ContactInfoCard.css";

const ContactInfoCard = ({ iconUrl, text, link }) => {

  const content = link ? (
    <a href={link} target={link.startsWith('mailto:') ? '_self' : '_blank'} rel="noopener noreferrer" className="contact-text">
      {text}
    </a>
  ) : (
    <p>{text}</p>
  );
  
  return (
    <div className="contact-details-card">
      <div className="icon">
        <img src={iconUrl} alt={text} />
      </div>
      {content}
    </div>
  );
};

export default ContactInfoCard;
