/* eslint-disable */
import React from 'react';
import './Experience.css'; 
import MainLayout from '../MainLayout/MainLayout';

const Experience = () => {
  return (
    <MainLayout>
    <div className="experience-container">
      
      <div className="experience-content">
        <section className="experience-section">
          <h2>Software Engineer @ Pocketly</h2>
          <p>July 2022 - June 2023</p>
          <ul>
            <li>Designed a financial console for mobile and web platforms solving a critical issue that enabled agents to identify and increase credit limits for qualifying salaried clients, enhancing user segmentation.</li>
            <li>Integrated an aggregator via Java to retrieve bank statements, streamlining data collection and improving process efficiency.</li>
            <li>Led QA initiatives to ensure the robustness and accuracy of the account aggregator and EPFO integration, reducing errors by 50% and improving system reliability.</li>
            <li>Constructed and instituted a real-time alert system for a financial monitoring platform using Embedded Software principles, markedly enhancing security through immediate anomaly detection and reporting.</li>
            <li>Deployed automation techniques with Python to slash average response time by 46%, boosting key service metrics.</li>
            <li>Engineered a robust UPI ID collection process for defaulted users, increasing repayment rates by 28% and improvingfinancial returns.</li>
            <li>Led the development of React Native iOS and Android applications, managing interface design, backend connectivity and shifting project requirements.</li>
          </ul>
          <p>Tools/Tech: NodeJS, ReactJS, React Native, TypeScript, SQL , MongoDB, Jira Boards, Postman, Jest</p>
        </section>

        {/* Repeat the section structure for other roles */}
        
        <section className="experience-section">
          <h2>Software Engineer Intern@ Pocketly</h2>
          <p>Jan 2022 - June 2022</p>
          <ul>
            <li>Engineered a CI/CD pipeline facilitating seamless deployment of cross-platform mobile applications.</li>
            <li>Integrated a calling system into the sales console, expanding user engagement and contributing to a 20% sales growth.</li>
            <li>Implemented a WhatsApp-based client helper interface across mobile and web platforms, enhancing real-time response capabilities and speeding issue resolution.</li>
            <li>Promptly identified and resolved critical user-facing issues in both the frontend and backend, boosting user retention.</li>
          </ul>
          <p>Tools/Tech: NodeJS, ReactJS, React Native, TypeScript, SQL , MongoDB, Jira Boards, Postman, Jest</p>
        </section>
        
        {/* ... additional sections for other roles */}

        <section className="experience-section">
          <h2>Software Engineer Intern @ Drushtant Infoweb Pvt. Ltd.</h2>
          <p>January 2021 - June 2021</p>
          <ul>
            <li>Customized demos of billing software to meet MSE business client needs, enhancing client engagement and receiving positive feedback on adaptability.</li>
            <li>Collaborated with the development team using Agile and Scrum methodologies to incorporate client feedback, driving product enhancements and achieving a 15% increase in client acquisition in one quarter.</li>
            {/* ... other points */}
          </ul>
          <p>Tools/Tech: NodeJS, ReactJS, SQL , MongoDB</p>
        </section>
      </div>
    </div>
    </MainLayout>
  );
};

export default Experience;
