
import React from 'react';
import Personal from '../../assets/images/Personal.jpeg'; 
import './ProfileHeader.css'; 

const ProfileHeader = () => {
  return (
    <div className="profile-header">
      <img src={Personal} alt="Nisarg Chudasama" className="profile-picture" />
      <h1>Nisarg Chudasama</h1>
      <p>nisargchudasama0@gmail.com</p> 
      <div className="social-links">
      </div>
    </div>
  );
};

export default ProfileHeader;
