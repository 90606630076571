/* eslint-disable */
import React from "react";
import "./MobileNav.css";
import { NavLink } from "react-router-dom";

const MobileNav = ({ isOpen, toggleMenu }) => {
  
  const handleScroll = (sectionId) => {
    if(isOpen) toggleMenu();
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <NavLink to="/" className="logo">
           Nisarg Chudasama
          </NavLink>


              <ul>
              <li><NavLink to="/about" className="menu-item" onClick={toggleMenu}>About</NavLink></li>
              <li><NavLink to="/education" className="menu-item" onClick={toggleMenu}>Education</NavLink></li>
              <li><NavLink to="/experience" className="menu-item" onClick={toggleMenu}>Experience</NavLink></li>
              <li><NavLink to="/skills" className="menu-item" onClick={toggleMenu}>Skills</NavLink></li>
              <li><NavLink to="/projects" className="menu-item" onClick={toggleMenu}>Projects</NavLink></li>
              <li><NavLink to="/contact" className="menu-item" onClick={toggleMenu}>Contact</NavLink></li>
              </ul>

        </div>
      </div>
    </>
  );
};

export default MobileNav;
