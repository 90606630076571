/* eslint-disable */
import React from 'react';
import './About.css'; 
import MainLayout from '../MainLayout/MainLayout';

const About = () => {
  
  return (
    <MainLayout>
    <div className="about-container">
       
      <div className="about-content">
        <h1>About Me</h1>
        <p>
          I am an enthusiastic developer with a robust foundation in programming. I excel in designing and developing scalable systems. Driven by insatiable curiosity and self-motivation, I quickly adapt and acquire new skills. Inspired by the transformative impact of technology over the past decade, I approach challenges with open-mindedness and creativity.
        </p>
        <p>
          I have worked as a Full Stack Software Engineer at Pocketly, where I contributed to the development of personal loan app and supporting features that enhanced the perforamce efficiency. I served on the Development and Application Performance Teams, tackling key projects across Frontend and Backend Development, System Architecture, and System Optimization.
        </p>
        <p>
          I am currently working towards a Master's degree in Computer Science at Dalhousie University, following my completion of an Integrated MBA+Btech in Information Technology degree from NMIMS.
        </p>
        <p>
          I have acquired the AWS cloud Practitioner Certificate as well as several LinkedIn Learning Certificates. I was a part of technical clubs in college and have organized tech fest event while partificapating in college sports fest. I am also a registered FIDE player.
        </p>
        <p>
          To learn more about my background, please explore the sections in the top navigation bar. Here, you can find detailed information about my Education, Experience, Skills, Projects, Certifications, and Contact details.
        </p>
        <p>Merci Beaucoup :)</p>
      </div>
    </div>
    </MainLayout>
  );
};

export default About;
