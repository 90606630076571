/* eslint-disable */
import React from 'react';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import './MainLayout.css'; // Create this CSS file

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <ProfileHeader />
      <div className="main-content">
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
