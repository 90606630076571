/* eslint-disable */
import React, { useState } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";
import { NavLink } from "react-router-dom"; 

function NavBar() {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className="nav-wrapper">
        <div className="nav-content">
          <NavLink to="/" className="logo">
           Nisarg Chudasama
          </NavLink>

            <ul>
              <li>
                <NavLink to="about" smooth spy offset={-80} className="menu-item">About</NavLink>
              </li>
              <li>
                <NavLink to="education" smooth spy offset={-80} className="menu-item">Education</NavLink>
              </li>
              <li>
                <NavLink to="experience" smooth spy offset={-80} className="menu-item">Experience</NavLink>
              </li>
              <li>
                <NavLink to="skills" smooth spy offset={-80} className="menu-item">Skills</NavLink>
              </li>
              <li>
                <NavLink to="projects" smooth spy offset={-80} className="menu-item">Projects</NavLink>
              </li>
              <li>
                <NavLink to="contact" smooth spy offset={-80} className="menu-item">Contact</NavLink>
              </li>
            </ul>


          <button class="menu-btn" onClick={toggleMenu}>
            <span
              class={"material-symbols-outlined"}
              style={{ fontSize: "1.8rem" }}
            >
              {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
