/* eslint-disable */
import React from "react";
import "./Hero.css";
import Cartoon from "../../assets/images/Cartoon.png";
import Docker from "../../assets/images/Docker.png";
import Kubernetes from "../../assets/images/Kubernetes.png";
import Java from "../../assets/images/Java.png";
import Node from "../../assets/images/Node.png";
import PersonalImage from "../../assets/images/PersonalImage.jpg";

const Hero = () => {
  return (
    <section id="hero" className="hero-container">
      <div className="hero-content">
        <h2>Hello, I am Nisarg</h2>
        <p>
          Software Engineer | Full Stack | Cloud | Data | QA
        </p>
        <p>
          Welcome to my portfolio
        </p>
      </div>

      <div className="hero-img">
        <div>
          <img src={PersonalImage} alt="" />
        </div>

        <div>
          <div className="tech-icon">
            <img src="./assets/images/img01.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img02.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img03.png" alt="" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img04.png" alt="" />
          </div>
          <div className="tech-icon">
          <img src={Docker} alt="" />
          </div>
          <div className="tech-icon">
          <img src={Kubernetes} alt="" />
          </div>
          <div className="tech-icon">
          <img src={Java} alt="" />
          </div>
          <div className="tech-icon">
          <img src={Node} alt="" />
          </div>

        </div>
      </div>
    </section>
  );
};

export default Hero;
